<template>
  <div id="recording">
    <div id="select">
    
      <div class="content">
       
        <div class="toptitle">
          <h4>营销中心</h4>
          <div class="right">
            <el-button type="danger"  size='mini' @click="pushall">群发短信</el-button>
            <el-button type="success"  size='mini' @click="closequan">群发优惠券</el-button>
            <el-button type="primary"  size='mini' @click="uplist">更新数据</el-button>
          </div>
        </div>
        <div
         
          class="money-list"
          v-loading="loading"
        >
          <div class="items" v-for="(item, index) in list" :key="index">
            <div class="left">
              <h6>
                {{ item.user_name }}   &nbsp;
                <el-tag size="mini" :type='item.type=="准客户"?"success":""'>{{
                  item.mtype
                }}</el-tag>
              </h6>
               <p>手机号:{{ item.tel_number}}</p>
              <p>{{ item.addtime }}</p>
            </div>
            <div class="right active" >
               <el-button type="primary" size='small' @click="push(item.id)">发短信</el-button>
               <el-button type="primary" size='small' @click="openyh(item.openid)">发优惠券</el-button>
            </div>
          </div>
            <el-button
          round
          @click="load"
          :icon="loading?'el-icon-loading':''"
          :disabled='loading'
          size="small"
          class="more btn"
          type="primary"
          >查看更多</el-button
        >
        </div>
       
      </div>

    </div>
     <div id="alert" v-show='share'>
        

        <div class="contents">
          <h6 class="tit">发送短信</h6>
          <div class="bindmobile">
              <el-input
              v-model="msg"   
              placeholder="请输入短信内容"
              class="input"
              ref='share'
            ></el-input>
           
          </div>

          <div class="bindmobile">
            tips:一条短信最多70字（包含签名），超出按多条计算，短信内容请遵守法律法规，不得发送违规信息，发现即封号。
          </div>
         
          <div class="choosebtn">
            <el-button type="primary" @click="pushmsg">确认</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </div>
      </div>
      <!--群发优惠券 -->
       <div id="alert" v-show='quan'>
        

        <div class="contents">
          <h6 class="tit">群发优惠券</h6>
           <div class="bindmobile">
              <el-input
              v-model="man"   
              placeholder="请输入满多少可以减扣(分)"
              class="input"
             
            ></el-input>
           
          </div>
          <div class="bindmobile">
              <el-input
              v-model="fee"   
              placeholder="请输入优惠券金额(分)"
              class="input"
              ref='share'
            ></el-input>
           
          </div>
          <div class="bindmobile">
              <el-input
              v-model="day"   
              placeholder="请输入有效天数(天)"
              class="input"
              ref='share'
            ></el-input>
           
          </div>
          <div class="bindmobile">
            每日自动发券：
            <el-switch
            v-model="auto"
            active-text=""
            inactive-text="">
          </el-switch>
          </div>
         
          <div class="choosebtn">
            <el-button type="primary" @click="addquan">确认</el-button>
            <el-button @click="closequan">取消</el-button>
          </div>
        </div>
      </div>
      

       <el-drawer
        title="优惠券列表"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
        :size='$store.state.isMobile?"100%":"40%"'
        >
        <div class="wuliu">
            <div class="items" v-for="(item,index) in  coupons" :key='index'>
                <div class="left">
                  <p>{{item.coupon_info.promote_info.customize_channel}} <el-tag size='mini' type="danger">{{item.coupon_info.discount_info.discount_fee/100}}元</el-tag></p>
                  <!-- <p style="margin-top:6px">有效期：{{item.create_time+'至'+item.update_time}}</p> -->
                </div>
                <div class="right">
                  <el-button type="danger" size='mini' @click="pushyh(item.coupon_id)">发送</el-button>
                </div>
            </div>
        </div>
        </el-drawer>
  </div>
</template>
<style lang="scss">
.toptitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
      border-bottom: 1px solid #ccc;

      h4{
        border: none!important;
      }
}
.wuliu {
  width: 90%;
  height: 80%;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: scroll;
  margin: 0 auto;

  .items{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px 0px #cedfe9;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 16px 16px;
    box-sizing: border-box;
  }
 
}
    h6{
        margin-bottom: 6px;
    }
     .tixian{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px;
   
  }
  .qrcode {
  margin: 0 auto;
  margin-bottom: 20px;

  canvas,
  img {
    display: block;
    margin: 0 auto;
  }
}
  h4{
      display: flex;
      align-items: center;
      justify-content: space-between;
  }
  .btn{
    display: block;
    margin: 20px auto;
  }
  .bindmobile{
      width: 100%;
  }
    @media screen and (max-width: 750px){
        #recording .content .money-list .items .right.active{
            font-size: 12px;
        }
        .toptitle{
          flex-wrap: wrap;

          .right{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }
        }
    }
</style>
<script>

export default {
  name: "invite",
  data() {
    return {
      page: 1,
      list: [],
      loading: false,
      msg:'',
      id:'',
      share:false,
      code:false,
      drawer:false,
      coupons:'',
      direction: "rtl",
      quan:false,
      day:"",
      fee:"",
      auto:false,
      man:"",
    };
  },
  methods: {

    //群发优惠券
    closequan(){
      this.quan=!this.quan
    },

    addquan(){
      const {day,fee,auto,man}=this;
      if(day&&fee){
        this.axios.get(this.api.qunquan,{
          params:{
            day,
            fee,
            man,
            auto:Number(auto)

          }
        })
        .then((res)=>{
          this.$message({
                message:res.code==0?"发送成功":"发送失败",
                type: res.code==0?'success':'warning'
              });
        })
      }
    },

    handleClose(){
      this.drawer=false
    },
     
     load() {
      this.page = this.page + 1;
      this.getList();
    },

    close(){
        this.share=!this.share
    },

    // 单发短信

    push(id){
        this.share=true;
        this.id=id;
    },
    //发送短信
    pushmsg(){
        const {msg,id}=this;
        if(msg){
           let params={
             msg,
           }
           if(id){
             params.id=id;
           }
            this.axios.get(id?this.api.msg:this.api.qunmsg,{
                params,
            })
            .then((res)=>{
                this.$message({
                message:res.msg,
                type: res.code==1?'success':'warning'
              });
            })
        }
    },
    //群发短信
    pushall(){
      this.id='';
      this.share=true;
    },
     
    //打开优惠券列表

    openyh(id){
      this.id=id;
      this.drawer=true;
    },

    //单发优惠券
    pushyh(yhqid){
      this.axios.get(this.api.danyouhui,{
        params:{
          openid:this.id,
          yhqid,
        }
      })
      .then((res)=>{
        this.$message({
          message: res.errcode==0?'发送成功':'发送失败',
          type: res.errcode==0?'success':'warning'
        });
      })
    },


    //优惠券列表
    yhlist(){
      this.axios.get(this.api.youhui)
      .then((res)=>{
        this.coupons=res.coupons
      })
    },
    
    
    //列表
    getList() {
      this.loading = true;
      this.axios
        .get(this.api.kehulist, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.loading = false;
          if (res != "") {
           
            this.list = [...this.list, ...res];
          } else {
            if (this.page >1) {
              this.$message({
                message: "暂无更多",
                type: "warning"
              });
            }
          }
        });
    },
    //更新数据

    uplist(){
      this.axios.get(this.api.uplist)
      .then((res)=>{
        if(res.code==0){
          this.$message({
                message: "更新成功",
                type: "success"
              });
              this.list=''
              this.page=1;
              this.getList()
        }else{
            this.$message({
                message: "更新失败",
                type: "warning"
              });
        }
      })
    },
    getDate(time) {
      var date = new Date(Number(time * 1000));
      let Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = date.getDate() + " ",
        h = date.getHours() + ":",
        m = date.getMinutes();
      // s = date.getSeconds();
      return Y + M + D + h + m;
    },
   
  },
  created() {
    this.getList();
    this.yhlist()
  
  
   
  }
};
</script>